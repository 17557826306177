import {onMounted, reactive, ref,computed, watch,} from "vue"
import {useRouter,useRoute} from "vue-router"
import $api from "@/api"
import dialog from "@/utils/ui/dialog"
export default function useLibraryDetail(){
    const router=useRouter()
    const route=useRoute()
    const {id}=route.query;
    const libraryDetail=reactive({})
    const getLibraryDetail=async(id)=>{
        const [err,data]=await $api.library.getLibraryDetail(id)
        if(!data.succeeded) {
            dialog.alert("抱歉",data.errors,true,false,()=>{
                router.push({
                    name:"Library"
                })
            })
            return;
        };
        Object.assign(libraryDetail,data.data)
    }

    const policyTop=ref([])
    const getPoliciesTop5=async()=>{
        const [err,data] =await $api.article.getPoliciesTop5({Number:5,IsHot:2});
        if(!data.succeeded) return;
        policyTop.value=data.data;
        
    }


    onMounted(async()=>{
        // 判断是否可以查看详情
            getLibraryDetail(id)
            getPoliciesTop5()
        
    })
    return {
        getLibraryDetail,libraryDetail,
        policyTop
    }
}