<template>
    <div class="inner_banner"><router-link class="db" to="/library"><img :src="require('@/assets/images/ztb_banner.png')"/></router-link></div>
    <main class="ztb_main policy_main policy_details">
      <div class="ztb_box">
        <div class="title">{{libraryDetail.title}}</div>
        <div class="cate_box cf">
          <span class="dib type fl" v-for="item in libraryDetail.articleLibraryName" :key="item">{{item}}</span>
          <span class="dib fr" v-formatTime>{{libraryDetail.createdTime}}</span>
          <i class="dib iconfont icon-clock fr"></i>
        </div>
        <h2>政策简介</h2>
        <p v-html="libraryDetail.abstracts"></p>
        <div class="btn_box w100p cf">
             <el-button type="primary" @click="downLoadFile(getImgUrl(libraryDetail.attachment),libraryDetail.title)"
            >下载<i class="el-icon-download el-icon--right"></i
            ></el-button>
        </div>
        
      </div>
      <div class="ztb_latest">
        <div class="sidebar_box">
        <div class="title">
         <span>政策推荐</span>
        </div>
        <ul>
          <li v-for="item in policyTop" :key="item.id"><a herf="javascript:void(0);" class="recomm-item" @click="showDetail(store, router, 'library', 'LibraryDetail', item,getLibraryDetail)">{{item.title}}</a></li>
        </ul>
        </div>
      </div>
    </main>
</template>
<script setup>
import {useStore} from "vuex"
import {useRouter} from "vue-router"
import useLibraryDetail from "@/composable/library/useLibraryDetail"
import {getImgUrl} from "@/utils/imageRelated"
import downLoadFile from "@/utils/file/downLoadFile"
import showDetail from "@/composable/universal/showDetail"

const router=useRouter()
const store=useStore()
const {getLibraryDetail,libraryDetail,policyTop}=useLibraryDetail()
</script>
<style lang="less" scoped>
    .recomm-item{
      cursor: pointer;
    }
</style>